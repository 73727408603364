import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";
import reportWebVitals from "./reportWebVitals";
import { HashRouter } from "react-router-dom";

ReactDOM.render(
  <Auth0Provider
    domain="dev--s-99e6y.eu.auth0.com"
    clientId="lGpCITwWTZ26G71wcLzdckI9myxW7RiA"
    //clientId={process.env.REACT_APP_AUTH_CLIENT_ID}
    redirectUri={window.location.origin}
    audience="https://internal-russian-api.inbrio.com"
    scope="read:current_user update:current_user_metadata read:messages"
  >
    <HashRouter>
      <App />
    </HashRouter>
  </Auth0Provider>,
  document.getElementById("root")
);

reportWebVitals();
