import React, { useRef } from "react";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import WordsTable from "../components/cities/citieslist";

import { useAuth0 } from "@auth0/auth0-react";
import { Form, Panel } from "rsuite";

import {
  Modal,
  ButtonToolbar,
  Button,
  Checkbox,
  Schema,
  Message,
} from "rsuite";
import CityService from "../services/cityService";

export default function Invoice() {
  const { getAccessTokenSilently } = useAuth0();
  const [cities, setCities] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);

  const formRef = useRef();

  const [message, setMessage] = useState("");

  const [formValue, setFormValue] = useState({
    fr: "",
    ru: "",
    verified: false,
  });

  const model = Schema.Model({
    fr: Schema.Types.StringType().isRequired("This field is required."),
    ru: Schema.Types.StringType().isRequired("This field is required."),
  });

  const handleClose = () => {
    setOpen(false);
    clearForm();
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const clearForm = () => {
    setFormValue({ fr: "", ru: "", verified: false });
    setMessage("");
  };

  let params = useParams();

  const handleSubmit = async () => {
    console.log(formValue);

    if (!formRef.current.check()) {
      console.log(formValue);
      return;
    }

    const token = await getAccessTokenSilently();

    const isAdded = await CityService.Add(token, formValue);

    if (isAdded.error) {
      setMessage(isAdded.error.code + " : " + isAdded.error.message);
    } else {
      clearForm();
      setOpen(false);
    }
    console.log(isAdded);
  };

  useEffect(() => {
    setIsLoaded(true);
  }, [params.id]);

  return (
    <div>
      <div className="container mx-auto">
        <Panel bordered>
          <div>
            <h3>Cities</h3>
          </div>
          <Modal open={open} onClose={handleClose} size="xs">
            <Modal.Header>
              <Modal.Title>New city</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {message ? <Message type="error">Error '{message}'</Message> : ""}

              <Form
                fluid
                onChange={setFormValue}
                formValue={formValue}
                ref={formRef}
                model={model}
              >
                <Form.Group controlId="fr">
                  <Form.ControlLabel>French</Form.ControlLabel>
                  <Form.Control name="fr" />
                  <Form.HelpText>Required</Form.HelpText>
                </Form.Group>
                <Form.Group controlId="ru">
                  <Form.ControlLabel>Russian</Form.ControlLabel>
                  <Form.Control name="ru" type="text" />
                  <Form.HelpText>Required</Form.HelpText>
                </Form.Group>
                <Form.Control
                  name="verified"
                  accepter={Checkbox}
                  checked={formValue.verified}
                  value={!formValue.verified}
                >
                  Verified ?
                </Form.Control>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleSubmit} appearance="primary">
                Confirm
              </Button>
              <Button onClick={handleClose} appearance="subtle">
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
          <ButtonToolbar>
            <Button onClick={handleOpen} appearance="primary">
              Add a new country
            </Button>
          </ButtonToolbar>
          <WordsTable
            words={cities}
            hide={true}
            setWords={setCities}
            loading={loading}
          />
        </Panel>
      </div>
    </div>
  );
}
