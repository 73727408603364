import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Panel } from "rsuite";
import CityService from "../../services/cityService";

const BlockCities = () => {
  const [count, setCount] = useState([]);

  useEffect(() => {
    load_table();
  }, []);

  async function load_table() {
    var t = await CityService.GetPaged(1, 1);
    setCount(t[1]);
  }

  return (
    <div>
      <Panel className="trend-box bg-gradient-blue">
        <div className="title">Number of cities</div>
        <div className="value">{count}</div>
        <div>
          <Link
            className="flex justify-between items-center group-hover:text-yellow-600"
            to="/cities/1"
          >
            <span className="text-sm">Go to cities</span>
            <span className="-translate-x-4 opacity-0 text-2xl transition duration-300 group-hover:opacity-100 group-hover:translate-x-0">
              {" >"}
            </span>
          </Link>
        </div>
      </Panel>
    </div>
  );
};

export default BlockCities;
