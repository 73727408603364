import React, { useEffect } from "react";
import {
  Form,
  ButtonToolbar,
  Button,
  Checkbox,
  Schema,
  toaster,
  Message,
} from "rsuite";

import { useAuth0 } from "@auth0/auth0-react";
import CountryService from "../../services/countryService";

const CountryEditForm = ({ word, onCancel }) => {
  const { getAccessTokenSilently } = useAuth0();
  const formRef = React.useRef();


  useEffect(() => {
    setFormValue({
      id: word.id,
      fr: word.fr,
      ru: word.ru,
      verified: word.verified,
    });
  }, []);

  const [formValue, setFormValue] = React.useState({
    id: -1,
    fr: "",
    ru: "",
    verified: false,
  });

  const model = Schema.Model({
    fr: Schema.Types.StringType().isRequired("This field is required."),
    ru: Schema.Types.StringType().isRequired(
      "Please enter a valid email address."
    ),
  });

  const handleSubmit = async () => {
    if (!formRef.current.check()) {
      console.log(formValue);
      toaster.push(<Message type="error">Error</Message>);
      return;
    }

    const token = await getAccessTokenSilently();

    const isUpdated = await CountryService.UpdateCountry(
      token,
      formValue.id,
      formValue
    );
    onCancel(false);
  };

  const handleCancel = async () => {
    onCancel(false);
  };

  return (
    <>
      <Form
        ref={formRef}
        model={model}
        formValue={formValue}
        onChange={(value) => {
          console.log(value);
          setFormValue(value);
        }}
        fluid
      >
        <Form.Group controlId="id">
          <Form.ControlLabel>Id</Form.ControlLabel>
          <Form.Control disabled name="id" />
        </Form.Group>
        <Form.Group controlId="fr">
          <Form.ControlLabel>French</Form.ControlLabel>
          <Form.Control name="fr" />
        </Form.Group>
        <Form.Group controlId="ru">
          <Form.ControlLabel>Email</Form.ControlLabel>
          <Form.Control name="ru" />
        </Form.Group>
        <Form.Control
          name="verified"
          accepter={Checkbox}
          checked={formValue.verified}
          value={!formValue.verified}
        >
          Verified ?
        </Form.Control>
        <Form.Group>
          <ButtonToolbar>
            <Button appearance="default" onClick={handleSubmit}>
              Submit
            </Button>
            <Button appearance="default" onClick={handleCancel}>
              Cancel
            </Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </>
  );
};

export default CountryEditForm;
