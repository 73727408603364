import React, { useState, useEffect } from "react";
import useModal from "../general/useModal";
import ModalWord from "../general/ModalWord";
import Drawer from "rsuite/Drawer";
import { Table, Pagination, IconButton } from "rsuite";
import CheckRoundIcon from "@rsuite/icons/CheckRound";
import WarningRoundIcon from "@rsuite/icons/WarningRound";
import ReloadIcon from "@rsuite/icons/Reload";

import VerbsService from "../../services/verbsService";
import VerbEditForm from "./verbeditform";

const { Column, HeaderCell, Cell } = Table;

const WordsTable = ({ words, hide, setWords, loadingold, nextPage }) => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [word, setWord] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    load_table();
  }, []);

  async function load_table() {
    setLoading(true);
    var t = await VerbsService.GetPagedVerbs(1, limit);
    setData(t[0]);
    setCount(t[1]);
    setLoading(false);
  }

  const [open, setOpen] = useState(false);

  const handleOpen = (rowData) => {
    setWord(rowData);
    setOpen(true);
  };

  const { isShowing, toggle } = useModal();
  const [editedWord, setEditedWord] = useState({
    fr: "",
    ru: "",
    verified: "",
  });

  const setNewPage = async (page) => {
    setLoading(true);
    var t = await VerbsService.GetPagedVerbs(page, limit);
    setData(t[0]);
    setPage(page);
    setLoading(false);
  };

  const handleChangeLimit = async (dataKey) => {
    setLoading(true);
    setLimit(dataKey);
    var t = await VerbsService.GetPagedVerbs(page, dataKey);
    setData(t[0]);
    setPage(page);
    setLoading(false);
  };

  const reload = async () => {
    setLoading(true);
    await setNewPage(page);
    setLoading(false);
  };

  return (
    <div>
      <div>
      <div className="reload-button">
        <IconButton
          onClick={reload}
          icon={<ReloadIcon />}
          color="blue"
          circle
        />
      </div>
        <Table autoHeight={true} data={data} loading={loading}>
          <Column width={60} align="center" fixed>
            <HeaderCell>Id</HeaderCell>
            <Cell dataKey="id" />
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>French</HeaderCell>
            <Cell dataKey="fr" />
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>Russian</HeaderCell>
            <Cell dataKey="ru" />
          </Column>
          <Column align="center">
            <HeaderCell>Verified</HeaderCell>
            <Cell>
              {(rowData) =>
                rowData.verified ? (
                  <CheckRoundIcon fill="green" style={{ fontSize: "2em" }} />
                ) : (
                  <WarningRoundIcon fill="red" style={{ fontSize: "2em" }} />
                )
              }
            </Cell>
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>Created</HeaderCell>
            <Cell>
              {(rowData) => (
                <span>
                  {new Date(rowData.created_at).toLocaleDateString("fr")}
                </span>
              )}
            </Cell>
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>Updated</HeaderCell>
            <Cell>
              {(rowData) => (
                <span>
                  {new Date(rowData.updated_at).toLocaleDateString("fr")}
                </span>
              )}
            </Cell>
          </Column>
          <Column width={80} fixed="right">
            <HeaderCell>Actions</HeaderCell>
            <Cell>
              {(rowData) => (
                <button
                  className="rounded-lg text-center"
                  onClick={(e) => handleOpen(rowData)}
                >
                  <span className="material-icons">edit</span>
                </button>
              )}
            </Cell>
          </Column>
        </Table>

        <div style={{ padding: 20 }}>
          <Pagination
            prev
            next
            first
            last
            boundaryLinks
            maxButtons={5}
            size="md"
            layout={["total", "-", "pager", "|", "limit"]}
            total={count}
            limitOptions={[10, 30, 50]}
            limit={limit}
            activePage={page}
            onChangePage={setNewPage}
            onChangeLimit={handleChangeLimit}
          />
        </div>
      </div>

      <ModalWord
        isShowing={isShowing}
        hide={toggle}
        header={"Edit a word / country"}
        word={editedWord}
        setWord={setWords}
        words={words}
      />

      <Drawer open={open} onClose={() => setOpen(false)}>
        <Drawer.Body>
          <VerbEditForm word={word} onCancel={setOpen} />
        </Drawer.Body>
      </Drawer>
    </div>
  );
};

export default WordsTable;
