import React from "react";
import { Field } from "formik";

const FormFieldCheckbox = ({ name, label }) => {
  return (
    <>
      <Field
        name={name}
        type="checkbox"
        className="rounded text-pink-500"
      />
      <label htmlFor="verified" className="pl-2">
        {label}
      </label>
    </>
  );

  // <Field name={name} type="checkbox" className="rounded text-pink-500" />;
};

export default FormFieldCheckbox;
