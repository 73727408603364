import React from "react";
import ReactDOM from "react-dom";
import WordForm from "../words/wordform";

const ModalWord = ({ isShowing, hide, word, setWord, header, words }) =>
  isShowing
    ? ReactDOM.createPortal(
        <>
          <div className="modal-overlay">
            <div className="modal-wrapper">
              <div className="modal">
                <div className="modal-header mb-4">
                  <h4>{header}</h4>
                  <button
                    type="button"
                    className="2modal-close-button"
                    onClick={hide}
                  >
                    <span className="material-icons">close</span>
                  </button>
                </div>
                <div>
                  <WordForm setWord={setWord} hide={hide} word={word} words={words} />
                </div>
              </div>
            </div>
          </div>
          <style jsx="true">{`
            .modal-overlay {
              position: fixed;
              top: 0;
              left: 0;
              width: 100vw;
              height: 100vh;
              z-index: 1040;
              background-color: rgba(0, 0, 0, 0.5);
            }

            .modal-wrapper {
              position: fixed;
              top: 0;
              left: 0;
              z-index: 1050;
              width: 100%;
              height: 100%;
              overflow-x: hidden;
              overflow-y: auto;
              outline: 0;
              display: flex;
              align-items: center;
            }

            .modal {
              z-index: 100;
              background: #fff;
              position: relative;
              margin: auto;
              border-radius: 5px;
              max-width: 500px;
              width: 80%;
              padding: 1rem;
            }

            .modal-header {
              display: flex;
              justify-content: space-between;
              align-items: center;
            }

            .modal-close-button {
              font-size: 1.4rem;
              font-weight: 700;
              color: #000;
              cursor: pointer;
              border: none;
              background: transparent;
            }
          `}</style>
        </>,
        document.body
      )
    : null;

export default ModalWord;
