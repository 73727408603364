import { Outlet } from "react-router-dom";
import { Container, Header, Content, Footer } from "rsuite";
import SideNavigation from "./sidenavigation";
import RCHeader from "./RCHeader";

function Layout() {
  return (
    <>
      <Container>
        <SideNavigation />
        <Container>
          <Header>
            <RCHeader />
          </Header>
          <Content>
            <Outlet />
          </Content>
          <Footer></Footer>
        </Container>
      </Container>
    </>
  );
}
export default Layout;
