import React from "react";
import { Row, Col, Panel } from "rsuite";
import BlockCountries from "../components/countries/blockcountries";
import BlockVerbs from "../components/verbs/blockverbs";
import BlockWords from "../components/words/blockwords";
import BlockCities from "../components/cities/blockcities";

function Home(props) {
  return (
    <div>
      <Panel className="title" title="Dashboard">
        <h3>Dashboard</h3>
        <div className="galerie">
          <BlockWords />
          <BlockVerbs />
          <BlockCountries />
          <BlockCities />
        </div>
        {/* <Row gutter={30} className="dashboard-header">
          <Col xs={8}>
            <BlockWords />
          </Col>
          <Col xs={8}>
            <BlockVerbs />
          </Col>
          <Col xs={8}>
            <BlockCountries />
          </Col>
          <Col xs={8}>
            <BlockCities />
          </Col>
        </Row> */}
      </Panel>
    </div>
  );
}

export default Home;
