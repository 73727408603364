import React from "react";
import FormLoader from "./formLoader";

const FormFieldButton = ({ onSubmit, isLoading, label}) => {
  var classes = "";

  if(isLoading) {
    classes = "bg-cyan-600 cursor-not-allowed";
  }
  else{
    classes = "";
  }

  return (
    <>
      <button
        type="submit"
        className={ "inline-flex items-center py-2 px-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-cyan-700 transition ease-in-out duration-150 "  + classes }
        disabled={isLoading}
      >
        {
          isLoading  ? 
          <FormLoader /> :
          ''
        }
        {label}
      </button>
    </>
  );
};

export default FormFieldButton;
