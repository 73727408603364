import { useAuth0 } from "@auth0/auth0-react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import LoginPage from "./pages/loginpage";

import "./App.css";
import Home from "./pages/home";
import ErrorBoundary from "./components/errorBoundary";
import Loader from "./components/general/loader";
import Layout from "./pages/layout";
import WordsPage2 from "./pages/wordsPage2";
import Words from "./pages/words";
import Countries from "./pages/countriesPage";
import Cities from "./pages/citiesPage";
import VerbsPage from "./pages/verbsPage";

function RequireAuth({ children }) {
  const { isAuthenticated, isLoading } = useAuth0();
  let location = useLocation();

  if (isLoading) {
    return (
      <div className="grid place-items-center h-screen">
        <Loader />
      </div>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

function App() {
  return (
    <ErrorBoundary>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/"
          element={
            <RequireAuth>
              <Layout />
            </RequireAuth>
          }
        >
          <Route index element={<Home />} />
          <Route path="words" element={<WordsPage2 />}>
            <Route path=":id" element={<Words />} />
          </Route>
          <Route path="countries" element={<WordsPage2 />}>
            <Route path=":id" element={<Countries />} />
          </Route>
          <Route path="cities" element={<WordsPage2 />}>
            <Route path=":id" element={<Cities />} />
          </Route>
          <Route path="verbs" element={<WordsPage2 />}>
            <Route path=":id" element={<VerbsPage />} />
          </Route>
        </Route>
      </Routes>
    </ErrorBoundary>
  );
}

export default App;
