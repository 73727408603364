import axios from "axios";

const VerbsService = {
  GetPagedVerbs: async function (page, limit) {
    var words = [];
    var pageCount = 0;

    await axios({
      method: "get",
      url: "/api/verbs/" + page + "?&limit=" + limit
    })
    .then(function (response) {
      words = response.data.results;
      pageCount = response.data.count;
    });
    
    return [words, pageCount];
  },
  UpdateVerb: async function (token, wordId, word) {
    var intword;
    await axios({
      method: "put",
      url: "/api/verbs/verb/" + wordId,
      headers: {
        Authorization: "Bearer " + token,
        Accept: "application/json",
      },
      data: {
        fr: word.fr,
        ru: word.ru,
        verified: word.verified,
      },
    })
      .then(function (response) {
        intword = response.data;
      })
      .catch(function (error) {
        console.log(error);
      });

    return intword;
  },

  AddVerb: async function (token, values) {
    var word;
    console.log(values);
    await axios({
      method: "post",
      url: "/api/verbs/verb/",
      headers: {
        Authorization: "Bearer " + token,
        Accept: "application/json",
      },
      data: {
        fr: values.fr,
        ru: values.ru,
        verified: values.verified,
      },
    })
      .then(function (response) {
        word = response.data;
      })
      .catch(function (error) {
        console.log("an error as occured while adding a word");
        word = error;
      });

    return word;
  },
};

export default VerbsService;
