import React, {useState} from "react";
import { Sidebar, Sidenav, Nav, DOMHelper } from "rsuite";
import DashboardIcon from "@rsuite/icons/legacy/Dashboard";
import GroupIcon from "@rsuite/icons/legacy/Group";
import MagicIcon from "@rsuite/icons/legacy/Magic";
import GearCircleIcon from "@rsuite/icons/legacy/GearCircle";
import { Link } from "react-router-dom";
import Brand from "./brand";
import NavToggle from "./NavToggle";
import TextImageIcon from '@rsuite/icons/TextImage';

const { getHeight, on } = DOMHelper;

const SideNavigation = () => {
  const [windowHeight, setWindowHeight] = useState(getHeight(window));

  const navBodyStyle = true
  ? { height: windowHeight - 112, overflow: 'auto' }
  : {};

  return (
    <>
      <Sidebar
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div>
          <Sidenav defaultOpenKeys={["3", "4"]}>
            <Sidenav.Header>
              <Brand />
            </Sidenav.Header>
            <Sidenav.Body style={navBodyStyle}>
              <Nav activeKey="1">
                <Nav.Item eventKey="1" icon={<DashboardIcon />}>
                <Link
                      className=""
                      to="/"
                    >
                      <span className="text-sm">Dashboard</span>
                    </Link>
                </Nav.Item>
                <Nav.Item eventKey="2" icon={<GroupIcon />}>
                  User Management
                </Nav.Item>
                <Nav.Menu eventKey="3" title="Vocabulary" icon={<MagicIcon />}>
                  <Nav.Item eventKey="3-1">
                    <Link
                      className="flex justify-between items-center group-hover:text-yellow-600"
                      to="words/1"
                    >
                      <span className="text-sm">Words</span>
                    </Link>
                  </Nav.Item>
                  <Nav.Item eventKey="3-2">
                    <Link
                      className="flex justify-between items-center group-hover:text-yellow-600"
                      to="/verbs/1"
                    >
                      <span className="text-sm">Verbs</span>
                    </Link>
                  </Nav.Item>
                  <Nav.Item eventKey="3-3">
                    <Link
                      className="flex justify-between items-center group-hover:text-yellow-600"
                      to="/countries/1"
                    >
                      <span className="text-sm">Countries</span>
                    </Link>
                  </Nav.Item>
                  <Nav.Item eventKey="3-3">
                    <Link
                      className="flex justify-between items-center group-hover:text-yellow-600"
                      to="/cities/1"
                    >
                      <span className="text-sm">Cities</span>
                    </Link>
                  </Nav.Item>
                </Nav.Menu>
                <Nav.Menu
                  eventKey="4"
                  title="Lessons"
                  icon={<TextImageIcon />}
                >
                  <Nav.Item eventKey="4-1">Lesson 01</Nav.Item>
                </Nav.Menu>
              </Nav>
            </Sidenav.Body>
          </Sidenav>
          <NavToggle expand={true} />
        </div>
      </Sidebar>
    </>
  );
};

export default SideNavigation;
