import React from "react";

import { Outlet } from "react-router-dom";

function WordsPage2(props) {
  return (
    <div>
      <div className="container mx-auto">
       <Outlet />
      </div>
    </div>
  );
}

export default WordsPage2;
