import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import CloseOutlineIcon from '@rsuite/icons/CloseOutline';

const LogoutButton = () => {
  const { logout } = useAuth0();
  return (
    <>
      <span className="logout-button" onClick={() => logout({ returnTo: window.location.origin })}><CloseOutlineIcon/></span>
    </>
  );
};

export default LogoutButton;
