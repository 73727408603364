import React from "react";
import { Link } from "react-router-dom";
import { Stack } from "rsuite";

const Brand = () => {
  return (
    <Stack alignItems="center" className="brand">
      <img src="./russian-flag-transparent-8.png" width="60px" height="30px" />
      <Link to="/">
        <span className="brand-title">Russian companion</span>
      </Link>
    </Stack>
  );
};

export default Brand;
