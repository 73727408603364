import React from "react";
import { Field, ErrorMessage } from "formik";

const FormFieldInputText = ({ name, label, isUpdating }) => {
  return (
    <>
      <label htmlFor="ru">{label}</label>
      <Field
        name={name}
        type="text"
        className="mt-1
                    block
                    w-full
                    rounded-md
                    border-gray-300
                    shadow-sm
                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
      />
      <span className="text-red-600">
        <ErrorMessage name={name} />
      </span>
    </>
  );
};

export default FormFieldInputText;
