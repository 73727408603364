import React, { useState } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { useAuth0 } from "@auth0/auth0-react";

import WordService from "../../services/wordService";
import FormFieldCheckbox from "../forms/formFieldCheckbox";
import FormFieldInputText from "../forms/formFieldInputText";
import FormFieldButton from "../forms/formFieldButton";

const WordForm = ({ setWord, hide, word, words }) => {
  const internalWord = word;
  const { getAccessTokenSilently } = useAuth0();
  const [isDisabled, setDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const initialValues = {
    ...(internalWord != null ? { fr: internalWord.fr } : { fr: "" }),
    ...(internalWord != null ? { ru: internalWord.ru } : { ru: "" }),
    ...(internalWord != null
      ? { verified: internalWord.verified }
      : { verified: true }),
  };

  const validationSchema = Yup.object().shape({
    fr: Yup.string().required("French word required"),
    ru: Yup.string().required("Russian word required"),
  });

  async function onSubmit(values, { setSubmitting }) {
    setErrorMessage("");
    setDisabled(true);
    const token = await getAccessTokenSilently();

    const wordCreated = await WordService.AddWord(token, values);

    if (wordCreated.error) {
      setErrorMessage(wordCreated.error.message);
      setDisabled(false);
    } else {
      hide();
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Form>
        {errorMessage.length > 0 ? (
          <div
            className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 mb-4"
            role="alert"
          >
            <p className="font-bold">An error as occured</p>
            <p>{errorMessage}</p>
          </div>
        ) : (
          ""
        )}

        <div className="mb-6">
          <FormFieldInputText
            name="fr"
            label="French"
            isUpdating={internalWord !== null ? true : false}
          />
        </div>
        <div className="mb-6">
          <FormFieldInputText name="ru" label="Russian" />
        </div>
        <div className="mb-6">
          <FormFieldCheckbox name="verified" label={"Is verified ?"} />
        </div>
        <div>
          <FormFieldButton
            onSubmit={onSubmit}
            isLoading={isDisabled}
            label="Submit"
          />
        </div>
      </Form>
    </Formik>
  );
};

export default WordForm;
