import { Container, Header, Content, Button, Navbar } from "rsuite";
import { useAuth0 } from "@auth0/auth0-react";

function LoginPage() {
  const { loginWithRedirect } = useAuth0();

  return (
    <div className="show-fake-browser login-page">
      <Container>
        <Header>
          {/* <Navbar appearance="inverse">
            <Navbar.Brand>
              <a style={{ color: "#fff" }}>Russian companion</a>
            </Navbar.Brand>
          </Navbar> */}
        </Header>
        <Content className="login-content">
          <div className="login-image">
            <img
              src="./russian-flag-transparent-8.png"
              width="160px"
              height="90px"
            />
          </div>
          <div>
            <Button appearance="primary" onClick={loginWithRedirect}>
              Sign in
            </Button>
          </div>
        </Content>
      </Container>
    </div>
  );
}

export default LoginPage;
